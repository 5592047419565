<template>
  <div>
    <r-app
      :sidebar-menu-data="{
        menus: menus,
        defaultIcon: 'brightness-1',
        logoUrl: '/images/logo.png',
        settings: {
          showedMenus: {
            firstLevel: 5,
            secondLevel: 2,
          },
        },
      }"
    >
      <template #header-right>
        <r-notification
          :notification-data="notificationData"
          :is-active="isNotificationActive"
          @showAllNotifications="() => (isActive = true)"
        />
        <r-profile
          :is-online="isOnline"
          :userData="{
            name: currentUser.user.user.email,
            role: currentUser.user.role,
          }"
          :user-page-link="'/profiles'"
          :settings-page-link="'/settings'"
        >
          <b-dropdown-item aria-role="listitem">Status page</b-dropdown-item>
          <b-dropdown-item aria-role="listitem">Help Center</b-dropdown-item>
          <b-dropdown-item separator></b-dropdown-item>
          <b-dropdown-item
            aria-role="listitem"
            tag="router-link"
            @click="logout()"
          >
            Logout
          </b-dropdown-item>
        </r-profile>
      </template>
      <template #default>
        <router-view />
      </template>
      <template #footer>
        <div class="level">
          <div class="level-left"></div>
          <div class="level-right">Space ID v{{ APP_VERSION }}</div>
        </div>
      </template>
    </r-app>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  computed: {
    // bottomMenus() {
    //   return [
    //     {
    //       title: 'Settings',
    //       basePath: '/settings',
    //       icon: 'cog',
    //       // popupPosition: "top",
    //       // isPopup: true,
    //       children: [
    //         {
    //           title: 'General Settings',
    //           path: '/general-settings',
    //           options: {
    //             attrs: {
    //               target: '_blank',
    //             },
    //           },
    //         },
    //       ],
    //     },
    //   ]
    // },
    menus() {
      console.log(this.currentUser.user.companyId)
      if (this.currentUser.user.companyId == 1) {
        console.log('aaaaaaaaaaaaa')
        return [
          {
            basePath: '/home',
            title: 'Home',
            icon: 'chart-timeline-variant',
            children: [
              {
                path: '/dashboard',
                title: 'Dashboard',
              },
              {
                path: '/path-count',
                title: 'Path Count',
              },
              {
                basePath: '/company-index',
                title: 'Company Index',
                children: [
                  {
                    path: '/active-company',
                    title: 'Active Company',
                  },
                  {
                    path: '/inactive-company',
                    title: 'Inactive Company',
                  },
                ],
              },
            ],
          },
          {
            basePath: '/payments',
            path: '/payments',
            title: 'Payments',
            icon: 'credit-card',
            children: [
              {
                basePath: '/payments',
                title: 'Payments',
                children: [
                  {
                    basePath: '/dashboard',
                    title: 'Dashboard',
                  },
                ],
              },
            ],
          },
          {
            basePath: '/voucher',
            title: 'Voucher',
            icon: 'tag',
            children: [
              {
                basePath: '/voucher',
                title: 'Voucher',
                children: [
                  {
                    path: '/dashboard',
                    title: 'Dashboard',
                  },
                ],
              },
              {
                basePath: '/redeemed',
                title: 'Redeemed',
                children: [
                  {
                    path: '/dashboard',
                    title: 'Dashboard',
                  },
                ],
              },
            ],
          },
          {
            basePath: '/apps',
            title: 'User Management',
            icon: 'account-supervisor',
            children: [
              // {
              //   basePath: '/1',
              //   title: 'RPM',
              //   children: [
              //     {
              //       path: '/dashboard',
              //       title: 'Dashboard',
              //     },
              //   ],
              // },
              {
                path: '/user-management/2',
                title: 'RISE',
                // children: [
                //   {
                //     path: '/user-management',
                //     title: 'Dashboard',
                //   },
                // ],
              },
            ],
          },
          {
            basePath: '/activity-log',
            title: 'Activity Log',
            icon: 'file-document-multiple',
            children: [
              {
                basePath: '/login',
                title: 'Login',
                children: [
                  {
                    path: '/dashboard',
                    title: 'Dashboard',
                  },
                ],
              },
            ],
          },
          {
            basePath: '/setting',
            title: 'Invite User',
            icon: 'account-plus',
            children: [
              {
                basePath: '/user',
                title: 'User',
                children: [
                  {
                    path: '/dashboard',
                    title: 'Dashboard',
                  },
                ],
              },
            ],
          },
          {
            path: '/questions',
            title: 'Questions',
            icon: 'help-circle',
          },
        ]
      } else {
        console.log('bbbbbbbbbbbbbb')

        return [
          // {
          //   basePath: '/home',
          //   title: 'Home',
          //   icon: 'chart-timeline-variant',
          //   children: [
          //     {
          //       path: '/dashboard',
          //       title: 'Dashboard',
          //     },
          //     {
          //       path: '/path-count',
          //       title: 'Path Count',
          //     },
          //   ],
          // },
          {
            basePath: '/billing',
            title: 'Billing',
            icon: 'credit-card',
            children: [
              {
                basePath: '/package',
                title: 'Package',
                children: [
                  {
                    path: '/dashboard',
                    title: 'Dashboard',
                  },
                ],
              },
              {
                basePath: '/payment',
                title: 'Payment',
                children: [
                  {
                    path: '/dashboard',
                    title: 'Dashboard',
                  },
                ],
              },
            ],
          },
          {
            basePath: '/apps',
            title: 'User Management',
            icon: 'account-supervisor',
            children: [
              // {
              //   basePath: '/1',
              //   title: 'RPM',
              //   children: [
              //     {
              //       path: '/dashboard',
              //       title: 'Dashboard',
              //     },
              //   ],
              // },
              {
                path: '/user-management/2',
                title: 'RISE',
                // children: [
                //   {
                //     path: '/user-management',
                //     title: 'Dashboard',
                //   },
                // ],
              },
            ],
          },
          {
            basePath: '/activity-log',
            title: 'Activity Log',
            icon: 'file-document-multiple',
            children: [
              {
                basePath: '/login',
                title: 'Login',
                children: [
                  {
                    path: '/dashboard',
                    title: 'Dashboard',
                  },
                ],
              },
            ],
          },
          {
            basePath: '/setting',
            title: 'Invite User',
            icon: 'account-plus',
            children: [
              {
                basePath: '/user',
                title: 'User',
                children: [
                  {
                    path: '/dashboard',
                    title: 'Dashboard',
                  },
                ],
              },
            ],
          },
          // {
          //   basePath: '/master-data',
          //   title: 'Master Data',
          //   icon: 'view-dashboard',
          //   children: [
          //     {
          //       basePath: '/limit',
          //       title: 'Limit',
          //       children: [
          //         {
          //           path: '/metode',
          //           title: 'Metode',
          //         },
          //         {
          //           path: '/cara-pembayaran',
          //           title: 'Cara Pembayaran',
          //         },
          //       ],
          //     },
          //   ],
          // },
        ]
      }
    },
    // mix the getters into computed with object spread operator
    ...mapGetters({
      currentUser: 'auth/currentUser',
    }),
  },
  data() {
    return {
      APP_VERSION: process.env.VUE_APP_VERSION
        ? process.env.VUE_APP_VERSION
        : '-',
      isNotificationActive: false,
      isOnline: false,
      notificationData: [
        {
          message: 'Ricky Septian asks to change his profile picture',
          date: 'Aug 19, 2020',
          time: '08:18',
        },
        {
          message: 'Ricky Septian asks to change his profile picture',
          date: 'Aug 19, 2020',
          time: '08:18',
        },
      ],
    }
  },
  methods: {
    logout() {
      this.$store.dispatch('auth/logout')
    },
  },
}
</script>
