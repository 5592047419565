var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('r-app',{attrs:{"sidebar-menu-data":{
      menus: _vm.menus,
      defaultIcon: 'brightness-1',
      logoUrl: '/images/logo.png',
      settings: {
        showedMenus: {
          firstLevel: 5,
          secondLevel: 2,
        },
      },
    }},scopedSlots:_vm._u([{key:"header-right",fn:function(){return [_c('r-notification',{attrs:{"notification-data":_vm.notificationData,"is-active":_vm.isNotificationActive},on:{"showAllNotifications":function () { return (_vm.isActive = true); }}}),_c('r-profile',{attrs:{"is-online":_vm.isOnline,"userData":{
          name: _vm.currentUser.user.user.email,
          role: _vm.currentUser.user.role,
        },"user-page-link":'/profiles',"settings-page-link":'/settings'}},[_c('b-dropdown-item',{attrs:{"aria-role":"listitem"}},[_vm._v("Status page")]),_c('b-dropdown-item',{attrs:{"aria-role":"listitem"}},[_vm._v("Help Center")]),_c('b-dropdown-item',{attrs:{"separator":""}}),_c('b-dropdown-item',{attrs:{"aria-role":"listitem","tag":"router-link"},on:{"click":function($event){return _vm.logout()}}},[_vm._v(" Logout ")])],1)]},proxy:true},{key:"default",fn:function(){return [_c('router-view')]},proxy:true},{key:"footer",fn:function(){return [_c('div',{staticClass:"level"},[_c('div',{staticClass:"level-left"}),_c('div',{staticClass:"level-right"},[_vm._v("Space ID v"+_vm._s(_vm.APP_VERSION))])])]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }